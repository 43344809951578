
/**
 * Get something out of the environment
 */
export function env(name: string) { 
  return process.env[`REACT_APP_${name}`] || "";
}

/**
 * Test if localstorage has an authToken
 */
export function hasAuthToken() {
  return Boolean(localStorage.getItem("authToken"));
}
  
/**
 * Check if route is login or register
 */
 export function isAuthRoute(pathname: string) {
  return [new RegExp("\\/login"), new RegExp("\\/register"), new RegExp("\\/createteam"), new RegExp("\\/createclient"), new RegExp("\\/createproject")].some(
    (route) => !!pathname.match(route)
  );
}

export const loveqoutes = [
  {
    id: 1,
    qoute: "In love there are two things: bodies and words.",
    writer: "Joyce Carol Oates"
  },
  {
    id: 2,
    qoute: "Continue to share your heart with people even if it has been broken.",
    writer: "Amy Poehler "
  },
  {
    id: 3,
    qoute: "To love is to recognize yourself in another.",
    writer: "Eckhart Tolle"
  },
  {
    id: 4,
    qoute: "To be fully seen by somebody, then, and be loved anyhow - this is a human offering that can border on miraculous.",
    writer: "Elizabeth Gilbert"
  },
  {
    id: 5,
    qoute: "There is always some madness in love. But there is also always some reason in madness.",
    writer: "Friedrich Nietzsche"
  },
  {
    id: 6,
    qoute: "Passion makes the world go 'round. Love just makes it a safer place.",
    writer: "Ice-T"
  },
  {
    id: 7,
    qoute: "When you find that one that's right for you, you feel like they were put there for you, you never want to be apart.",
    writer: "Joe Manganiello"
  },
  {
    id: 8,
    qoute: "When you're lucky enough to meet your one person, then life takes a turn for the best. It can't get better than that.",
    writer: "John Krasinski"
  },
  {
    id: 9,
    qoute: "You know it's love when all you want is that person to be happy, even if you're not part of their happiness.",
    writer: "Julia Roberts"
  },
  {
    id: 10,
    qoute: "Love has nothing to do with what you are expect to get — only with what you are expecting to give — which is everything.",
    writer: "Katharine Hepburn"
  },
  {
    id: 11,
    qoute: "Where there is great love, there are always miracles.",
    writer: "Willa Cather"
  },
  {
    id: 12,
    qoute: "In real love, you want the other person's good. In romantic love, you want the other person.",
    writer: "Margaret Anderson"
  },
  {
    id: 13,
    qoute: "Have enough courage to trust love one more time and always one more time.",
    writer: "Maya Angelou"
  },
  {
    id: 14,
    qoute: "True love is singing karaoke Under Pressure and letting the other person sing the Freddie Mercury part.",
    writer: "Mindy Kaling"
  },
  {
    id: 15,
    qoute: "When you realize you want to spend the rest of your life with sombody, you want the rest of your life to start as soon as possible.",
    writer: "Nora Ephron"
  },
  {
    id: 16,
    qoute: "Where we love is home - home that our feet may leave, but not our hearts.",
    writer: "Oliver Wendell Holmes Sr."
  },
  {
    id: 17,
    qoute: "Love is that condition in which the happiness of another person is essential to your own.",
    writer: "Robert A. Heinlein"
  },
  {
    id: 18,
    qoute: "I love you not only for what you are, but for what I am when I am with you.",
    writer: "Roy Croft"
  },
  {
    id: 19,
    qoute: "When you are missing someone, time seems to move slower, and when I'm falling in love with someone, time seems to be moving faster.",
    writer: "Taylor Swift"
  },
  {
    id: 20,
    qoute: "You found parts of me I didn't know exists and in you I fould a love I no longer believed was real.",
    writer: "Unknown"
  },
  {
    id: 21,
    qoute: "Love makes your soul crawl out from its hiding place.",
    writer: "Zora Neale Hurston"
  },
  {
    id: 22,
    qoute: "There is only one happiness in this life, to love and be loved.",
    writer: "George Sand"
  },
  {
    id: 23,
    qoute: "I swear I couldn't love you more than I do right now, and yet I know I will tomorrow.",
    writer: "Leo Christopher"
  },
]