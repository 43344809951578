import { env } from "./tools";

/**
 * The API url
 */
export const API_URL = env("API_URL");

/**
 * Get the default fetch headers
 */
export const FETCH_HEADERS = () => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "X-User-Agent": "admin",
    },
  };
};
