import React, { useEffect, createContext, useState, ReactNode, useContext } from "react";
import { useHistory } from "react-router";
import { Relationship } from "../../types";
import Loading from "../components/helpers/Loading";
import { API_URL, FETCH_HEADERS } from "../constants";
import { hasAuthToken } from "../tools";
import { ModalContext } from "./modal";

export interface RelationshipContextType {
  relationship: undefined | Relationship;
}

const RELATIONSHIP_CONTEXT_DEFAULT = {
    relationship: undefined,
};

export const RelationshipContext = createContext<RelationshipContextType>(RELATIONSHIP_CONTEXT_DEFAULT);

interface Props {
  children?: ReactNode;
};

/**
 * User Provider
 * @param props 
 * @returns 
 */
export default function RelationshipProvider(props: Props) {
  const [relationship, setRelationship] = useState<undefined | Relationship>();
  const [loading, setLoading] = useState(true);
  const {refetch, handleRefetch} = useContext(ModalContext);

  /**
   * useEffect to get the current logged in user
   *
   */
  useEffect(() => {
    fetchRelationship();
    handleRefetch(false);
  }, [refetch]);

  async function fetchRelationship() {
    if (hasAuthToken()) {
      const res = await fetch(`${API_URL}/relationship/get`, {
        ...FETCH_HEADERS(),
      });
      const json = await res.json();
      const relationship = json?.data;
      if (relationship) {
        setRelationship(relationship)
        setLoading(false);
      }
      return json;
    } else {
      setLoading(false);
    }
  }

  if (loading) return <Loading message='Requesting memories'/>;

  return (
    <RelationshipContext.Provider
      value={{
        relationship,
      }}
    >
      {props.children}
    </RelationshipContext.Provider>
  );
}