import React, { Fragment, useContext } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import Login from "../../pages/auth/Login";
import Home from "../../pages/Home";
import RelationshipProvider from "../../providers/relationship";
import { UserContext } from "../../providers/user";
import RedirectOrComponent from "./RedirectOrComponent";

export default function RouteCollection() {
  const {isAuthed} = useContext(UserContext);

  return (
    <Switch>
      <Route exact path="/">
        <RelationshipProvider>
          <RedirectOrComponent
              condition={isAuthed}
              Component={Home}
              redirectTo=""
            />
        </RelationshipProvider>
      </Route>
      <Route exact path="/login">
          <RedirectOrComponent
              condition={!isAuthed}
              Component={Login}
              redirectTo=""
            />
      </Route>
    </Switch>
  )
}