import Compressor from "compressorjs";
import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useHistory } from "react-router";
import { LaravelErrorCollection, Memory } from "../../../types";
import { API_URL } from "../../constants";
import { ModalContext } from "../../providers/modal";
import useRequest from "../fetching/useRequest";
import { useImageWidth } from "./useImageWidth";

type Response = {
  memory: Memory,
}


interface PostData {
    title: string;
    date: string;
    imagepath: Blob | null;
    collection_id: number
}


export default function useCreateMemory() {
  const [values, setValues] = useState<PostData>({
    title: "",
    date: "",
    imagepath: null,
    collection_id: 1
  });
  const [errors, setErrors] = useState<LaravelErrorCollection>(null);
  const {handleModalFormStatus, handleModalState, handleRefetch} = useContext(ModalContext);
  const [data, setData] = useState<Response>();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setValues({...values, [e.target.name]: e.target.value});
  }

  const blobToFile = (theBlob: Blob, fileName:string): File => {       
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

  async function handleImageChange (event: React.ChangeEvent<HTMLInputElement>) {
      if(event.target.files) {
        const image = event.target.files[0];
        new Compressor(image, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                console.log(compressedResult);
              // compressedResult has the compressed file.
              // Use the compressed file to upload the images to your server.        
              setValues((prevFormValues) => ({
                ...prevFormValues,
                imagepath: event.target.files ? compressedResult : null,
              }));
            },
          });
      }
  };

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();
      
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("date", values.date);
    formData.append("collection_id", values.collection_id.toString());
    values.imagepath && formData.append("imagepath", values.imagepath);

    console.log(formData);

    const request = await fetch(API_URL + '/memory/create', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: formData,
    });


    const res = await request.json();
    console.log(res);

    if (res?.errors) {
        setErrors(res.errors);
    }

    if (res.data) {
        handleModalFormStatus(true);
        handleModalState("",false);
        handleRefetch(true);
    }
  }

  return {
    values,
    onChange,
    onSubmit,
    handleImageChange,
    errors,
  }
}