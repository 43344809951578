import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { useHistory } from "react-router";
import { User } from "../../../types";
import { API_URL } from "../../constants";
import { UserContext } from "../../providers/user";
import useRequest from "../fetching/useRequest";

type Response = {
  user: User,
  token: string,
}

type Variables = typeof LOGIN_DEFAULT_VALUES;

const LOGIN_DEFAULT_VALUES = {
  email: "",
  password: ""
}

export default function useLogin() {
  const {storeLoggedinUser, setAuthedUserState} = useContext(UserContext);
  const [values, setValues] = useState(LOGIN_DEFAULT_VALUES);
  const [login, { loading, errors }] = useRequest<Response, Variables>({
    url: `${API_URL}/login`,
    variables: {...values},
  });
  const history = useHistory();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setValues({...values, [e.target.name]: e.target.value});
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    console.log(API_URL);

    const res = await login();

    if (res?.data?.user?.id) {
      const user = res.data.user;
      const token = res.data.token;
      localStorage.setItem("authToken", token);
      setAuthedUserState();
      storeLoggedinUser(user, () => history.push("/"));
    }
  }

  return {
    values,
    onChange,
    onSubmit,
    loading,
    errors,
  }
}