import { createContext, useState, ReactNode } from "react";
import ModalCreateMemory from "../components/modals/ModalCreateMemory";

export interface ModalContextType {
  showModal: boolean;
  modalName: undefined | string;
  handleModalState: (modalName: string, showModal: boolean) => void;
  handleModalFormStatus: (modalFormStatus: boolean) => void;
  modalFormStatus: boolean;
  handleModalInfo: (info: any) => void;
  modalInfo: any;
  handleRefetch: (bool: boolean) => void;
  refetch: undefined | boolean;
}

const MODAL_CONTEXT_DEFAULT = {
  showModal: false,
  modalName: undefined,
  handleModalState: (modalName: string, showModal: boolean) => undefined,
  handleModalFormStatus: (modalFormStatus: boolean) => undefined,
  modalFormStatus: false,
  handleModalInfo: (info: any) => undefined,
  modalInfo: undefined,
  handleRefetch: (bool: boolean) => undefined,
  refetch: undefined
};

export const ModalContext = createContext<ModalContextType>(MODAL_CONTEXT_DEFAULT);

interface Props {
  children?: ReactNode;
};

/**
 * Rider Provider
 * @param props 
 * @returns 
 */
export default function ModalProvider(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState<undefined | string>();
  const [modalFormStatus, setModalFormStatus] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [refetch, setRefetch] = useState<undefined | boolean>();

  function handleModalState(modalName: string, showModal: boolean) {
    setModalName(modalName);
    setShowModal(showModal);
  }

  function handleModalFormStatus(modalFormStatus: boolean) {
    setModalFormStatus(modalFormStatus);
  }

  function handleModalInfo(info: any) {
    setModalInfo(info);
  }

  function handleRefetch(bool: boolean) {
    setRefetch(bool);
  }

  return (
    <ModalContext.Provider
      value={{
        showModal,
        modalName,
        handleModalState,
        modalFormStatus,
        handleModalFormStatus,
        handleModalInfo,
        modalInfo,
        handleRefetch,
        refetch
      }}
    >
    {modalName == "ModalCreateMemory" && showModal == true ? <ModalCreateMemory/>: false}
      {props.children}
    </ModalContext.Provider>
  );
}