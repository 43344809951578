import { useContext } from "react"
import { ModalContext } from "../../providers/modal";
import dinosaur from '../../../images/bug-cat-capoo-dino.gif';

export default function Navbar() {
    const {handleModalState} = useContext(ModalContext);
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <div className="navbar__branding">
                    <img className="navbar__dinosaur" src={dinosaur} />
                    <a className="navbar-brand" href="/">Luvision</a>
                </div>

                <div className="navbar__item">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <p className="nav-link" onClick={() => handleModalState('ModalCreateMemory', true)}>Add a new memory<div className="nav-link__icon"><i className="ri-heart-add-line"></i></div></p>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}