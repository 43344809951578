import React, { Fragment, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from 'react-router-dom';

import { isAuthRoute } from './tools';

import ProvidersCollection from './providers';
import RouteCollection from './components/routing/RouteCollection';
import Navbar from './components/layout/Navbar';
import ModalProvider from './providers/modal';

function App() {
  const {pathname} = useLocation();
  const authRoute = isAuthRoute(pathname);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ProvidersCollection>
      <ModalProvider>
        <div className="app">
          {authRoute ? 
            <RouteCollection/>
          :
          <Fragment>
              <Navbar/>
              <RouteCollection/>
          </Fragment>
          }
        </div>
      </ModalProvider>
    </ProvidersCollection>
  );
}

export default App;
