import { ChangeEvent, Fragment } from "react"


type Props = {
  className: string,
  name: string,
  type: string,
  accept: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: null | undefined | string,
}

export default function FileInput({error, name, className, type, accept, onChange} : Props) {
  return (
    <Fragment>
      <input
        className={className}
        name={name}
        type={type}
        onChange={onChange}
        accept={accept}
      ></input>
      {error ? <p className="form__group-error">{error}</p> : null}
    </Fragment>
  )
}