import React from 'react';

type Props = {
    message: string,
}

export default function Loading({message} : Props)  {
    return (
        <div className="loading container">
            <div className='loading__wrapper'>
                <div className="loading__inner">
                    <div className="loading__inner-spinner">
                        <div className="loadingio-spinner-rolling-wtp2uby56tg">
                            <div className="ldio-5q6n2yu62z">
                                <div></div>
                            </div>
                        </div>
                    </div>
                <p className='loading__message'>{message}</p>
                </div>
            </div>
        </div>
    );
}
