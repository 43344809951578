import React from "react";
import { Redirect } from "react-router-dom";

interface Props {
  condition: boolean;
  redirectTo: string;
  Component: React.ComponentType;
}

export default function RedirectOrComponent(props: Props) {
  const { condition, redirectTo, Component } = props;
  return condition ? <Component /> : <Redirect to={redirectTo} />;
}