import { useState } from "react";
import { LaravelErrorCollection } from "../../../types";
import { FETCH_HEADERS } from "../../constants";

type Props<TVariables> = {
    url: string;
    variables? : TVariables;
    customFetchOptions?: RequestInit;
};

type RequestFn<TData> = () => Promise<RequestResult<TData>>;

type RequestHook<TData> = [
    RequestFn<TData>,
    { data: null | TData; loading: boolean; errors: LaravelErrorCollection }
];

type RequestResult<TData> = null | {
    data: TData | null;
    errors: LaravelErrorCollection;
};

export default function useRequest<TData, TVariables>(
    props: Props<TVariables>
) : RequestHook<TData> {
    const { url, variables, customFetchOptions } = props;
    const [data, setData] = useState<TData | null>(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<LaravelErrorCollection>(null);

    async function runRequest(): Promise<RequestResult<TData>> {
        try {
          setLoading(true);
          setErrors(null);
    
          const requestInit = {
            method: "POST",
            ...FETCH_HEADERS(),
            body: JSON.stringify({
              ...variables
            }),
            ...customFetchOptions,
          };
    
          const res = await (await fetch(url, requestInit)).json();
          
          if (res?.errors) {
            setErrors(res.errors);
          }
    
          if (res?.data) {
            setData(res.data);
          }
          return res;
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
    
        return null;
      }
    
      return [runRequest, { data, loading, errors }];
}