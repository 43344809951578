import React from "react";
import { Link } from "react-router-dom";
import TextInput from "../../components/inputs/TextInput";
import useLogin from "../../hooks/auth/useLogin";
import picture from "../../../images/luvisionloginbg.png";

export default function Login() {
  const {values, onChange, onSubmit, errors, loading} = useLogin();
  return (
    <div className="login container">
        <div className="login__background" style={{backgroundImage: `url(${picture})`}}></div>
        <div className="login__form">
        <div className="login__heading">
            <h1 className="login__heading-title">Login</h1>
        </div>
        <form className="login__form-inner" onSubmit={onSubmit}>
            <div className="login__form-group">
                <label>Email</label>
                <TextInput  
                className="login__form-input"
                name="email"
                type="email"
                placeholder="Enter your email"
                onChange={onChange}
                value={values.email}
                error={errors?.login?.[0]}
                />
            </div>
            <div className="login__form-group">
                <label>Password</label>
                <TextInput  
                className="login__form-input"
                name="password"
                type="password"
                placeholder="Enter password"
                onChange={onChange}
                value={values.password}
                error={errors?.password?.[0]}
                />
            </div>
            <button className="login__form-btn c-btn c-btn--primary" type="submit">
                <span>Login</span>
            </button>
        </form>
        </div>
    </div>
  )
}