import React, { useEffect, createContext, useState, ReactNode } from "react";
import { useHistory } from "react-router";
import { User } from "../../types";
import Loading from "../components/helpers/Loading";
import { API_URL, FETCH_HEADERS } from "../constants";
import { hasAuthToken } from "../tools";

export interface UserContextType {
  storeLoggedinUser: (user: User, callback: () => void) => void;
  refetchUser: () => Promise<void>;
  removeLoggedinUser: () => void;
  setAuthedUserState: () => void;
  isAuthed: boolean;
  user: undefined | User;
}

const USER_CONTEXT_DEFAULT = {
  storeLoggedinUser: (user: User) => undefined,
  removeLoggedinUser: () => undefined,
  refetchUser: async () => undefined,
  setAuthedUserState: () => undefined,
  isAuthed: false,
  user: undefined,
};

export const UserContext = createContext<UserContextType>(USER_CONTEXT_DEFAULT);

interface Props {
  children?: ReactNode;
};

/**
 * User Provider
 * @param props 
 * @returns 
 */
export default function UserProvider(props: Props) {
  const [user, setUser] = useState<undefined | User>();
  const [loading, setLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);
  const history = useHistory();

  /**
   * useEffect to get the current logged in user
   *
   */
  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    if (hasAuthToken()) {
      const res = await fetch(`${API_URL}/user`, {
        ...FETCH_HEADERS(),
      });
      const json = await res.json();
      const user = json?.data;
      if (user?.id) {
        setUser(user)
        setIsAuthed(true);
        setLoading(false);
      }

      if(json.message === "Unauthenticated.") {
        setLoading(false);
        history.push('/login');
      }

      if (!isAuthed) {
        history.push('/login');
      }
      return json;
    } else {
      history.push('/login');
      setLoading(false);
    }
  }

  async function refetchUser() {
    fetchUser();
  }

  /**
   * Function to store a (new) user in Provider
   *
   */
  function storeLoggedinUser(user: User, callback?: () => void) {
    setUser(user)
    callback?.()
  }

  function setAuthedUserState() {
    setIsAuthed(true);
  }

  /**
   * Function to remove the logged in user from the state and localstorage
   *
   */
  function removeLoggedinUser() {
    setUser(undefined);
    setIsAuthed(false);
    localStorage.removeItem("token");
  }

  if (loading) return <Loading message='Requesting user'/>;

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthed,
        setAuthedUserState,
        storeLoggedinUser,
        removeLoggedinUser,
        refetchUser
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}