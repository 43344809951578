import { useContext } from "react"
import Masonry from "react-masonry-css";
import { Memory, Relationship } from "../../types";
import { RelationshipContext } from "../providers/relationship";
import _, { groupBy } from 'underscore';
import moment from "moment";
import { loveqoutes } from "../tools";
import { API_URL } from "../constants";

export default function Home() {    
  const {relationship} = useContext(RelationshipContext);

  const months = [
    {
      id: "01",
      month: "January"
    },
  ]

  const groupBy = (array: any, key: any) => {
    // Return the end result
    return array.reduce((result: any, currentValue: any) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };

  var groupByMonthMemories: any[] = [];

  if(relationship?.collections) {
    groupByMonthMemories = groupBy(relationship?.collections[0].memories, 'month');
  }
  return (
      <div className="home page">
        <div className="home__wrapper">
          <div className="home__memories">
            <div className="container">
              <div className="row">
              {groupByMonthMemories ? 
                Object.entries(groupByMonthMemories).reverse().map(([key,value]) => {
                  const quote = loveqoutes[Math.floor(Math.random()*loveqoutes.length)];
                  return (
                    <div className="home__memories-memory" key={key}>
                      <div className="home__memories-heading">
                        <h1 className="home__memories-date"><span></span>{moment(key).format('MMMM Y')}</h1>
                        <div className="home__memories-quotes">
                          <div>
                          <p className="home__memories-quote">"{quote.qoute}"</p>
                          <p className="home__memories-writer">— {quote.writer}</p>
                          </div>
                        </div>
                      </div>
                      <Masonry breakpointCols={2} className="grid" columnClassName="grid-item">
                        {value.map((memory: Memory) => {
                          return (
                            <div className="home__memory" data-aos="fade-up" key={memory.id}>
                              <img loading="lazy" className="home__memories-image" src={`https://api.luvision.nl/storage/${memory.imagepath}`}></img>
                              <div className="home__memory-hover m-hover">
                                <h3 className="m-hover__title">{memory.title}</h3>
                                <p className="m-hover__date">{moment(memory.date).format('MMMM Do YYYY')}</p>
                              </div>
                            </div>
                          )
                        })}
                      </Masonry>
                    </div>
                  )
                })
                :
                undefined
                }
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}