import { ChangeEvent, Fragment } from "react"


type Props = {
  className: string,
  name: string,
  type: string,
  placeholder?: undefined | string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string,
  error?: null | undefined | string,
}

export default function TextInput({error, name, className, type, placeholder, onChange, value} : Props) {
  return (
    <Fragment>
      <input
        className={className}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      ></input>
      {error ? <p className="form__group-error">{error}</p> : null}
    </Fragment>
  )
}