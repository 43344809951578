import React, { ReactNode, ReactText, useRef } from "react";
import UserProvider from "./user";

type Props = {
  children?: ReactNode;
};


export default function ProvidersCollection(props: Props) {

  return (
      <UserProvider>
          {props.children}
      </UserProvider>
  );
}