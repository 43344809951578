import { useContext } from "react";

import useCreateMemory from "../../hooks/memory/useCreateMemory";
import { ModalContext } from "../../providers/modal";
import FileInput from "../inputs/FileInput";
import TextInput from "../inputs/TextInput";

export default function ModalCreateMemory() {
    const {handleModalState} = useContext(ModalContext);
    const {onSubmit, onChange, handleImageChange, errors, values} = useCreateMemory();

  return (
    <div className="w-modal">
      <div className="w-modal__close" onClick={() => handleModalState('ModalCreateMemory', false)}>
      </div>
      <div className="w-modal__wrapper">
          <div className="w-modal__inner">
            <div className="w-modal__header">
                <div className="w-modal__header-inner">
                    <div className="w-modal__header-title">
                        <p>Add a new memory</p>
                    </div>
                    <div className="w-modal__header-closebtn" onClick={() => handleModalState('ModalCreateMemory', false)} >
                        <i className="ri-close-fill"></i>
                    </div>
                </div>
            </div>
            <div className="w-modal__body">
                <form onSubmit={onSubmit}>
                    <div className="form__group">
                        <label className="form__group-label">Picture</label>
                        <FileInput className="form__group-input"
                        name="imagepath"
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        error={errors?.imagepath?.[0]}
                        />
                        {/* <input type="file" name="imagepath" onChange={handleImageChange} accept="image/*"></input> */}
                    </div>
                    <div className="form__group">
                        <label className="form__group-label">Memory title</label>
                        <TextInput className="form__group-input"
                        name="title"
                        type="text"
                        placeholder="First kiss"
                        onChange={onChange}
                        value={values.title}
                        error={errors?.title?.[0]}
                        />
                    </div>
                    <div className="form__group">
                        <label className="form__group-label">Date</label>
                        <TextInput className="form__group-input"
                        name="date"
                        type="date"
                        placeholder="10-02-2006"
                        onChange={onChange}
                        value={values.date}
                        error={errors?.date?.[0]}
                        />
                    </div>            
                    <button className="form__form-btn c-btn c-btn--primary" type="submit">
                        <span>Add memory</span>
                    </button>
                </form>
            </div>
          </div>
      </div>
    </div>
  )
}